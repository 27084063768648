<template>
  <div class="pd20x flex1">
    <div class="h100pct ofA">
      <div v-if="tagsId">
        <questionList :id="tagsId" :type="typeList[typeIndex].type"></questionList>
      </div>
    </div>
  </div>
</template>
<script>
import * as http from '@/libs/examapi'
import questionList from './subject/questionList'
export default {
  name: 'subject',
  components:{ questionList},
  data(){
    return{
      subjectList:[],

      tabIndex: 0,
      currentIndex: 0,
      result: [],
      tags: [],
      edit:false,
      type: '0',

      tagsId: undefined,
      typeList:[
        {label:'基础设置',value:1},
        {label:'套卷列表',value:2, type: 1}
      ],
      typeIndex: 0,
    }
  },
  mounted(){
    this.tagsId = this.$route.query.id
  },
  methods:{
    changeType(index){
      this.typeIndex = index
    },
    getTags(id){
      return new Promise((resolve,reject)=>{
        http.get_subject({cate_id:id}).then(res=>{
          if(res.length){
            this.tags = res
          }else{
            this.tags = []
          }
          resolve()
        }).catch(error=>{
          reject
          console.log(error)
        })
      })
    },
    getCourse(){
      this.result = []
      if(this.currentIndex == 0 || this.tags.length){
        http.get_course({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
          if(res.length){
            res.forEach(el=>{
              el.expend = true
            })
            this.result = res
          }else{
            this.result = []
          }
        }).catch(error=>{
          console.log(error)
        })
      }
    },
    getProblems(){
      this.result = []
      if(this.currentIndex == 0 || this.tags.length){
        http.get_problems({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
          if(res.length){
            res.forEach(el=>{
              el.expend = true
            })
            this.result = res
          }else{
            this.result = []
          }
        }).catch(error=>{
          console.log(error)
        })
      }
    },
    getCates(){
      http.get_cate().then(res=>{
        this.dealArr(res)
        this.subjectList = res
      }).catch(error=>{
        console.log(error)
      })
    },
    dealArr(data){
      data.forEach(el => {
        if(el.son.length){
          this.dealArr(el.son)
        }else{
          delete el.son
        }
      })
    },
    handleChange(){
      if(this.type == 0){
        this.getCourse()
      }else{
        this.getProblems()
      }
    },
    deleteItem(id){
      let _this = this
      this.$confirm({
        title: '是否确定删除该条信息?',
        centered: true,
        onOk() {
          if(_this.type == 0){
            http.del_course({id}).then(res=>{
              _this.getCourse()
            }).catch(error=>{
              console.log(error)
            })
          }else{
            http.del_problems({id}).then(res=>{
              _this.getProblems()
            }).catch(error=>{
              console.log(error)
            })
          }
        },
      });
    },
    log(){
      let result = { ids:this.result.map(el=>el.id).join(',')}
      if(this.type == 0){
        http.order_course(result).then(res=>{
        }).catch(error=>{
          this.$message.error('拖拽失败，请重新操作')
          this.getData()
          console.log(error)
        })
      }else{
        http.order_Problems(result).then(res=>{
        }).catch(error=>{
          this.$message.error('拖拽失败，请重新操作')
          this.getProblems()
          console.log(error)
        })
      }
    },
    changePlus(index){
      this.result[index].expend = !this.result[index].expend
    },
    changeTab(index){
      this.tabIndex = index;
      this.type == 0 ? this.getCourse() : this.getProblems()
    }
  }
}
</script>
<style lang="less" scoped>
.addBtn{ position: absolute; right: 20px; bottom: 10px; }
.plusIcon{ font-size: 20px; margin-right: 20px; color: #333; vertical-align: middle; cursor: pointer; }
.groups{ background: #fff; padding: 10px 20px; }
.dragWrap{ border-radius: 6px; overflow: hidden; margin-bottom: 5px; }
.dragBody.border{ border-radius: 0; margin-bottom: 2px; }
.radioGroup .radioItem{ margin-top: 10px; margin-bottom: 10px; border-radius: 4px; }
.radioGroup .radioItem.active{ background: #ff5f4e; border-bottom: 1px; color: #fff;}
.nodata{
  text-align: center;
}
</style>
